@font-face {
    font-family: 'Bozon';
    src: url('Bozon-BlackItalic.eot');
    src: local('Bozon Black Italic'), local('Bozon-BlackItalic'),
        url('Bozon-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-BlackItalic.woff2') format('woff2'),
        url('Bozon-BlackItalic.woff') format('woff'),
        url('Bozon-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon Extra';
    src: url('Bozon-ExtraBoldItalic.eot');
    src: local('Bozon Extra Bold Italic'), local('Bozon-ExtraBoldItalic'),
        url('Bozon-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-ExtraBoldItalic.woff2') format('woff2'),
        url('Bozon-ExtraBoldItalic.woff') format('woff'),
        url('Bozon-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-Thin.eot');
    src: local('Bozon Thin'), local('Bozon-Thin'),
        url('Bozon-Thin.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Thin.woff2') format('woff2'),
        url('Bozon-Thin.woff') format('woff'),
        url('Bozon-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-Bold.eot');
    src: local('Bozon Bold'), local('Bozon-Bold'),
        url('Bozon-Bold.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Bold.woff2') format('woff2'),
        url('Bozon-Bold.woff') format('woff'),
        url('Bozon-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon Ultra';
    src: url('Bozon-UltraThin.eot');
    src: local('Bozon Ultra Thin'), local('Bozon-UltraThin'),
        url('Bozon-UltraThin.eot?#iefix') format('embedded-opentype'),
        url('Bozon-UltraThin.woff2') format('woff2'),
        url('Bozon-UltraThin.woff') format('woff'),
        url('Bozon-UltraThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon Hairline';
    src: url('Bozon-Hairline.eot');
    src: local('Bozon Hairline'), local('Bozon-Hairline'),
        url('Bozon-Hairline.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Hairline.woff2') format('woff2'),
        url('Bozon-Hairline.woff') format('woff'),
        url('Bozon-Hairline.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon Extra';
    src: url('Bozon-ExtraBold.eot');
    src: local('Bozon Extra Bold'), local('Bozon-ExtraBold'),
        url('Bozon-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Bozon-ExtraBold.woff2') format('woff2'),
        url('Bozon-ExtraBold.woff') format('woff'),
        url('Bozon-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-BoldItalic.eot');
    src: local('Bozon Bold Italic'), local('Bozon-BoldItalic'),
        url('Bozon-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-BoldItalic.woff2') format('woff2'),
        url('Bozon-BoldItalic.woff') format('woff'),
        url('Bozon-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-Black.eot');
    src: local('Bozon Black'), local('Bozon-Black'),
        url('Bozon-Black.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Black.woff2') format('woff2'),
        url('Bozon-Black.woff') format('woff'),
        url('Bozon-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon Ultra';
    src: url('Bozon-UltraThinItalic.eot');
    src: local('Bozon Ultra Thin Italic'), local('Bozon-UltraThinItalic'),
        url('Bozon-UltraThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-UltraThinItalic.woff2') format('woff2'),
        url('Bozon-UltraThinItalic.woff') format('woff'),
        url('Bozon-UltraThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-Light.eot');
    src: local('Bozon Light'), local('Bozon-Light'),
        url('Bozon-Light.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Light.woff2') format('woff2'),
        url('Bozon-Light.woff') format('woff'),
        url('Bozon-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon Demi';
    src: url('Bozon-DemiBold.eot');
    src: local('Bozon Demi Bold'), local('Bozon-DemiBold'),
        url('Bozon-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('Bozon-DemiBold.woff2') format('woff2'),
        url('Bozon-DemiBold.woff') format('woff'),
        url('Bozon-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon Book';
    src: url('Bozon-BookItalic.eot');
    src: local('Bozon Book Italic'), local('Bozon-BookItalic'),
        url('Bozon-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-BookItalic.woff2') format('woff2'),
        url('Bozon-BookItalic.woff') format('woff'),
        url('Bozon-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon Hairline';
    src: url('Bozon-HairlineItalic.eot');
    src: local('Bozon Hairline Italic'), local('Bozon-HairlineItalic'),
        url('Bozon-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-HairlineItalic.woff2') format('woff2'),
        url('Bozon-HairlineItalic.woff') format('woff'),
        url('Bozon-HairlineItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon Demi';
    src: url('Bozon-DemiBoldItalic.eot');
    src: local('Bozon Demi Bold Italic'), local('Bozon-DemiBoldItalic'),
        url('Bozon-DemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-DemiBoldItalic.woff2') format('woff2'),
        url('Bozon-DemiBoldItalic.woff') format('woff'),
        url('Bozon-DemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon Book';
    src: url('Bozon-Book.eot');
    src: local('Bozon Book'), local('Bozon-Book'),
        url('Bozon-Book.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Book.woff2') format('woff2'),
        url('Bozon-Book.woff') format('woff'),
        url('Bozon-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-ThinItalic.eot');
    src: local('Bozon Thin Italic'), local('Bozon-ThinItalic'),
        url('Bozon-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-ThinItalic.woff2') format('woff2'),
        url('Bozon-ThinItalic.woff') format('woff'),
        url('Bozon-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-RegularItalic.eot');
    src: local('Bozon Regular Italic'), local('Bozon-RegularItalic'),
        url('Bozon-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-RegularItalic.woff2') format('woff2'),
        url('Bozon-RegularItalic.woff') format('woff'),
        url('Bozon-RegularItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-Regular.eot');
    src: local('Bozon Regular'), local('Bozon-Regular'),
        url('Bozon-Regular.eot?#iefix') format('embedded-opentype'),
        url('Bozon-Regular.woff2') format('woff2'),
        url('Bozon-Regular.woff') format('woff'),
        url('Bozon-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Bozon';
    src: url('Bozon-LightItalic.eot');
    src: local('Bozon Light Italic'), local('Bozon-LightItalic'),
        url('Bozon-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Bozon-LightItalic.woff2') format('woff2'),
        url('Bozon-LightItalic.woff') format('woff'),
        url('Bozon-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

