//
// Font Colors
//

.text-dark {
  color: $gray-200 !important;
}

//
// Background colors
//


.bg-white {
  background-color: $theme-dark-card-bg !important;
}

.bg-light {
  background-color: $theme-dark-bg-light !important;
}

.bg-dark {
  background-color: $gray-600 !important;
}

// 
// table
// 
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: rgba($color: $danger, $alpha: 0.2) !important;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: rgba($color: $success, $alpha: 0.2) !important;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: rgba($color: $primary, $alpha: 0.2) !important;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: rgba($color: $info, $alpha: 0.2) !important;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: rgba($color: $warning, $alpha: 0.2) !important;
}


// 
// light colors
// 
.bg-light-info {
  background-color: #31556b !important;
}

.bg-light-success {
  background-color: #2d6134 !important;
}

.bg-light-warning {
  background-color: #6f6045 !important;
}

.bg-light-danger {
  background-color: #884a58 !important;
}

.bg-light-inverse {
  background-color: #544f4f !important;
}

.bg-light-primary {
  background-color: #413873 !important;
}

.badge-light {
  background-color: $gray-800;
  color: $theme-dark-text-color;
}

.btn-light {
  background-color: $gray-800;
  border-color: $gray-800;
  color: $theme-dark-text-color;
}

.badge-light-success {
  background-color: #516d55;
}

.badge-light-info {
  background-color: #27546f;
}

.badge-light-danger {
  background-color: #583a41;
}


.alert-light {
  background-color: rgba($color: #fdfefe, $alpha: 0.7);
  border-color: rgba($color: #fcfdfe, $alpha: 0.3);
}

//   calendar app
.rbc-toolbar button {
  background-color: rgba($color: $info, $alpha: 0.5);
  border-color: rgba($color: $info, $alpha: 0.5);
  color: $white;
  &:hover {
    background-color: rgba($color: $info, $alpha: 0.7);
    border-color: rgba($color: $info, $alpha: 0.5);
    color: $white;
  }
}

.rbc-toolbar button.active,
.rbc-toolbar button.rbc-active {
  background-color: rgba($color: $info, $alpha: 0.8);
  border-color: rgba($color: $info, $alpha: 0.8);
}

.rbc-event.event-default {
  background-color: rgba($color: $info, $alpha: 0.5);
}

.rbc-event.event-azure {
  background-color: rgba($color: $cyan, $alpha: 0.5);
}
.rbc-event.event-orange {
  background-color: rgba($color: $warning, $alpha: 0.5);
}
.rbc-event.event-green {
  background-color: rgba($color: $success, $alpha: 0.5);
}
.rbc-event.event-red {
  background-color: rgba($color: $danger, $alpha: 0.5);
}


