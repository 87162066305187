// Responsive
/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/

/*============================================================== 
 For Desktop & above all (1650px) 
 ============================================================== */

/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */
@media (min-width: 1024px) {
  .page-wrapper {
    margin-left: $sidebar-width-full;
  }
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@include media-breakpoint-up(md) {
  
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@include media-breakpoint-down(md) {
  
  .topbar .mega-dropdown .dropdown-menu {
    height: 100%;
  }
}
