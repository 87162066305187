@import url(./assets/fonts/fonts.css);

/* variables */
$purple: #563d82;
$white: #fff;
$purple-dark: #451d8a;
$grayLight: #3e5569;
$gray: #ccc;
$whiteLight: #e0e0e0;

body {
  font-family: "Bozon", sans-serif;
}

.carousel-inner {
  border-radius: 4px;
  box-shadow: 3px 3px 3px #a1a1a1;
}

.loader {
  border: 8px solid $gray;
  border-top: 8px solid $purple-dark;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

input {
  background: #edf0f7;
}

.page-link.active,
.active > .page-link {
  background-color: $purple;
  border-color: $purple;
}

.card-unitive {
  background: white;
  box-shadow: 3px 3px 3px #a1a1a1;
  padding: 1rem;
  margin-bottom: 1rem;
}

.text-unitive {
  color: $purple;
}

.social > img {
  cursor: pointer;
}

.btn-outline-unitive {
  border-color: #c47474;
  color: #c47474;
  padding: 1rem;
  margin: 0.5rem;
  font-size: 1rem !important;
  &:hover {
    background-color: $purple;
    color: $white;
  }
}

#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul
  .sidebar-item.active
  > .sidebar-link
  .feather {
  color: $purple;
}

.logo-unafraid {
  width: 42.02%;
}

.logo-unitive {
  width: 1.938rem;
}

.nav-link,
.nav-link:hover {
  color: $purple;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: $purple;
  color: $white;
}

.text-footer {
  font-weight: 500;
  font-size: 0.5rem;
}

/* btn custom for unitive*/
.btn-unitive {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #3a3a3a;
  border-radius: 4px;
  height: 2.75rem;
  display: block;
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  font-size: 0.875rem;
}

.btn-unitive:hover,
.btn-unitive:active,
.btn-unitive.active,
.open .dropdown-toggle.btn-unitive {
  color: $white !important;
  background: #493865 !important;
  display: block;
  border-color: #493865 !important;
}

.btn-unitive.disabled,
.btn-unitive[disabled],
fieldset[disabled] .btn-unitive,
.btn-unitive.disabled:hover,
.btn-unitive[disabled]:hover,
fieldset[disabled] .btn-unitive:hover,
.btn-unitive.disabled:focus,
.btn-unitive[disabled]:focus,
fieldset[disabled] .btn-unitive:focus,
.btn-unitive.disabled:active,
.btn-unitive[disabled]:active,
fieldset[disabled] .btn-unitive:active,
.btn-unitive.disabled.active,
.btn-unitive[disabled].active,
fieldset[disabled] .btn-unitive.active {
  background-color: $purple;
  border-color: $purple;
}

.btn-unitive .badge {
  color: $purple;
  background-color: $white;
}

/*btn unitive secondary*/
.btn-unitive-secondary {
  color: $grayLight;
  background-color: $white;
  border-color: $purple;
  height: 40px;
}

.btn-unitive-secondary:hover,
.btn-unitive-secondary:focus,
.btn-unitive-secondary:active,
.btn-unitive-secondary.active,
.open .dropdown-toggle.btn-unitive-secondary {
  color: $grayLight;
  background-color: $whiteLight;
  border-color: $purple;
}

.btn-unitive-secondary:active,
.btn-unitive-secondary.active,
.open .dropdown-toggle.btn-unitive-secondary {
  background-image: none;
}

.btn-unitive-secondary.disabled,
.btn-unitive-secondary[disabled],
fieldset[disabled] .btn-unitive-secondary,
.btn-unitive-secondary.disabled:hover,
.btn-unitive-secondary[disabled]:hover,
fieldset[disabled] .btn-unitive-secondary:hover,
.btn-unitive-secondary.disabled:focus,
.btn-unitive-secondary[disabled]:focus,
fieldset[disabled] .btn-unitive-secondary:focus,
.btn-unitive-secondary.disabled:active,
.btn-unitive-secondary[disabled]:active,
fieldset[disabled] .btn-unitive-secondary:active,
.btn-unitive-secondary.disabled.active,
.btn-unitive-secondary[disabled].active,
fieldset[disabled] .btn-unitive-secondary.active {
  background-color: $white;
  border-color: $purple;
}

.btn-unitive-secondary .badge {
  color: $white;
  background-color: $grayLight;
}

/*simple link*/
.link-unitive {
  color: #c9b17f;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.form-check-input:checked {
  background-color: $purple;
  border-color: $purple;
}

.btn-unitive .badge {
  color: $purple;
  background-color: $white;
}

.btn-unitive-del {
  color: #ffffff;
  background-color: #c94a4a;
  border-color: #ffffff;
  height: 40px;
}

.btn-unitive-del:hover,
.btn-unitive-del:focus,
.btn-unitive-del:active,
.btn-unitive-del.active,
.open .dropdown-toggle.btn-unitive-del {
  color: #ffffff;
  background-color: #b01313;
  border-color: #ffffff;
}

.btn-unitive-del:active,
.btn-unitive-del.active,
.open .dropdown-toggle.btn-unitive-del {
  background-image: none;
}

.btn-unitive-del.disabled,
.btn-unitive-del[disabled],
fieldset[disabled] .btn-unitive-del,
.btn-unitive-del.disabled:hover,
.btn-unitive-del[disabled]:hover,
fieldset[disabled] .btn-unitive-del:hover,
.btn-unitive-del.disabled:focus,
.btn-unitive-del[disabled]:focus,
fieldset[disabled] .btn-unitive-del:focus,
.btn-unitive-del.disabled:active,
.btn-unitive-del[disabled]:active,
fieldset[disabled] .btn-unitive-del:active,
.btn-unitive-del.disabled.active,
.btn-unitive-del[disabled].active,
fieldset[disabled] .btn-unitive-del.active {
  background-color: #c94a4a;
  border-color: #ffffff;
}

.btn-unitive-del .badge {
  color: #c94a4a;
  background-color: #ffffff;
}

.apexcharts-yaxis-texts-g tspan {
  font-size: 0.8rem !important;
}

.modal-content {
  width: 85%;
  left: 6%;
}

.video-component > .modal-content {
  width: 99% !important;
  left: 0% !important;
}

@media only screen and (max-width: 300px) {
  body {
    font-size: 0.7rem !important;
  }

  .topbar .nav-toggler,
  .topbar .topbartoggler {
    padding: 0 0.5rem;
  }

  .me-2 {
    margin-right: 0rem !important;
  }

  .search-box {
    margin-left: 0rem !important;
  }
}

.flex-direction-column {
  flex-direction: column;
}

.select-paginate {
  border-radius: 1rem;
  margin-left: 1rem;
  background-color: #d1bef2;
  border-color: #563d82 !important;
}

.logo-unafraid {
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.no-pad {
  padding: 0px;
}

.g_id_signin {
  display: none;
}

#credential_picker_container {
  display: none;
}

ul.list-none > .Anxiety,
.Anxiety {
  background-color: #c7b197 !important;
}

.page-link {
  background: transparent !important;
  border: none !important;
}

.page-item.active > .page-link {
  background-color: #3a3a3a !important;
  border-radius: 4px;
}

ul.list-none > .Fear,
.Fear {
  background-color: #a6ae85 !important;
}

ul.list-none > .Regret,
.Regret {
  background-color: #a8b7cc !important;
}

ul.list-none > .Addiction,
.Addiction {
  background-color: #c47474 !important;
}

ul.list-none > .Insecurity,
.Insecurity {
  background-color: #d5b17e !important;
}

ul.list-none > .Depression,
.Depression {
  background-color: #523963 !important;
}

.h100 {
  height: 100%;
}

.text-white {
  color: white;
}

.fw700 {
  font-weight: 700;
}

.row > * {
  padding: 0px;
}

.pad-default {
  padding: 0px 2.25rem;
}

.card-type1 {
  background: #e8d8f9;
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 10px;
  margin: 2.25rem;
  padding: 1rem 1.8rem;
}

#main-wrapper[data-layout="vertical"] .left-sidebar[data-sidebarbg="skin6"],
#main-wrapper[data-layout="horizontal"] .left-sidebar[data-sidebarbg="skin6"],
#main-wrapper[data-layout="vertical"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul,
#main-wrapper[data-layout="horizontal"]
  .left-sidebar[data-sidebarbg="skin6"]
  .sidebar-nav
  ul {
  background: #e8d8f9;
}

.title-section {
  color: #3a3a3a;
  font-weight: 700;
  font-size: 2rem;
}

.c-pointer {
  cursor: pointer;
}

.dark-light {
  color: #3a3a3a;
}

.goal-user {
  color: #393939;
  font-size: 1rem;
}

.icon-size {
  padding: 0.2rem;
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 0.3rem;
}

.modal-header {
  border: none;
  padding-bottom: 0rem;
}

input,
textarea {
  background: #fff3e2;
}

textarea,
input {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #393939 !important;
}

input,
.spaninput {
  height: 44px;
}

::placeholder {
  font-size: 12px;
}

#ngx .ng-valid,
.ng-touched.ng-valid {
  border-color: #fff3e2;
}

#ngx .ng-invalid,
.ng-touched.ng-invalid {
  border-color: #fff3e2;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #fff3e2;
}

.form-control:disabled {
  background-color: #fff3e2;
}

app-toasts ngb-toast.bg-success {
  background-color: #393939 !important;
}

.white {
  color: white;
}

.font-back {
  color: #383737;
}

.logo-bottom {
  padding-top: 1.5rem;
}

.title-edit {
  color: #563d82;
  font-size: 1.25rem;
  font-weight: 700;
}

.font-unafraid {
  font-weight: 800;
}

.font-opt {
  font-size: 0.75rem;
  color: #3d3d3d;
}

.plus-1 {
  background-color: #e5e5e5;
  border: 1px solid #d0d0d0;
}

.logo-autheticate-modal {
  width: 9.313rem;
}

.modal-content {
  background: #e8d8f9;
}

.title-dark {
  color: #3a3a3a;
}

.text-dark {
  color: #e86969 !important;
  font-size: 0.75rem;
}

.card-autheticate {
  background: #e8d8f9 !important;
  box-shadow: 0px 4px 15px 0px #00000026 !important;
  border-radius: 10px;
  width: 22.438rem;
}

.logo-autheticate {
  width: 4.375rem;
  padding-top: 1.5rem;
}

.title-autheticate {
  color: #563d82;
  font-size: 1.75rem;
}

.sub-title-autheticate {
  color: #563d82;
  font-size: 0.75rem;
}
.box-autheticate {
  padding: 0.9375rem;
}

.imput-autheticate {
  border-radius: 4px;
  border: 1px;
  border: 1px solid #9f9f9f;
  background: #faf4ff;
}

::placeholder {
  font-size: 0.875rem;
}

.box-autheticate-ga {
  padding: 0px 0.9375rem;
}
.center-text {
  display: block;
  width: 100%;
}

.apple-icon {
    background-size: 50% 50%;
    width: 17px;
    height: 17px;
  }
  
.align-center-logo
{
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.margin-footer {
  margin: 0.5rem;
  margin-bottom: 1rem;
}

.font-p {
  color: #563d82;
  font-size: 0.875rem;
}

.font-send {
  font-size: 0.875rem;
  font-weight: 600;
}

.btn-secondary {
  height: 2.75rem;
}

body {
  touch-action: manipulation;
  background-color: #ae8ed0;
}

.sidebar-nav ul .sidebar-item .sidebar-link {
  padding: 13px 4px;
}

.btn-unitive[disabled] {
  color: white;
}

// Butterfly post editor css

.butterfly-post-editor .fa,
.butterfly-post-editor .fas {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900 !important;
}

.butterfly-post-editor
  .angular-editor
  .angular-editor-wrapper
  .angular-editor-textarea {
  background-color: #f5f5f5 !important;
  margin-top: 0px !important;
  border-radius: 0px 0px 4px 4px;
  padding-bottom: 50px !important;
}

.butterfly-post-editor hr {
  border-top: 1px solid #282e33 !important;
  opacity: 0.6 !important;
}

.butterfly-post-editor .angular-editor-textarea {
  color: #393939;
}

.butterfly-post-editor .angular-editor-toolbar {
  border-radius: 4px 4px 0px 0px;
  padding-top: 10px !important;
}

.butterfly-effect-pagination .page-item > .page-link {
  color: white !important;
}

.butterfly-effect-pagination .disabled > .page-link {
  color: var(--bs-pagination-disabled-color) !important;
}

.butterfly-effect-pagination .page-item.active > .page-link {
  color: #282e33 !important;
  background: white !important;
}

.butterfly-post-editor img {
  width: 50%;
  display: block;
}
// Butterfly post editor css

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
