// 
// Horizontal Layout
// 
$sidebarheight: 54px;
#main-wrapper[data-layout="horizontal"] {
  // language change ellipsis text
  @include media-breakpoint-up(lg) {
    .sidebar-nav {
      #sidebarnav {
        > .sidebar-item {
          > .sidebar-link {
            .hide-menu {
              max-width: 55px;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .sidebar-nav ul .sidebar-item .sidebar-link {
      padding: 14px 0;
    }
  }

  // 
  // Fixed Header Layout
  // 
  &[data-header-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }
    .left-sidebar {
      padding-top: 64px;
    }
  }
  
  // 
  // Fixed Sidebar Layout
  // 
  &[data-sidebar-position="fixed"] {
    .topbar {
      position: fixed;
      width: 100%;
    }
    .left-sidebar {
      padding-top: 64px;
      position: fixed;
    }
    .page-wrapper {
      padding-top: 78px;
    }
  }
}

// 
// Horizontal Layout
// 

@include media-breakpoint-up(lg) {
  #main-wrapper[data-layout="horizontal"] {
    background: $body-bg;
    .topbar {
      transition: 0s;
    }
    // 
    // Boxed Horizontal Layout
    // 
    &[data-boxed-layout="boxed"] {
      .top-navbar,
      .sidebar-nav,
      .scroll-sidebar,
      .page-wrapper {
        max-width: $boxed-width;
        margin: 0 auto;
        position: relative;
      }

      .sidebar-nav #sidebarnav > .mega-dropdown .first-level {
        max-width: $boxed-width;
        margin: 0 auto;
      }

      .sidebar-nav {
        #sidebarnav {
          flex-wrap: wrap;
          > .sidebar-item > .has-arrow:after {
            display: block;
          }
        }
        // .sidebar-item {
        //     flex: 1 1 0;
        // }
      }
    }
    &[data-sidebar-position="fixed"] {
      .page-wrapper {
        padding-top: 118px;
      }
    }
    // 
    // Horizontal Layout
    // 
    .topbar .top-navbar .navbar-header {
      width: 200px;
      // border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    .topbar .sidebartoggler {
      display: none;
    }
    .left-sidebar {
      width: 100%;
      height: auto;
      position: relative;
      padding-top: 0px;
      z-index: 45;
      transition: 0s;
      .scroll-sidebar {
        height: $sidebarheight;
      }
    }
    .sidebar-nav ul {
      display: flex;
    }

    .scroll-sidebar {
      height: auto;
    }

    .sidebar-nav {
      #sidebarnav {
        display: flex;

        width: 100%;
        .sidebar-item {
          position: relative;
          width: auto;
        }
        > .sidebar-item {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          &:last-child {
            border-right: none;
          }
          > .has-arrow:after {
            transform: rotate(-135deg) translate(0, -50%);
          }
          &:last-child > .first-level {
            right: 0px;
            left: auto;
          }
          &.two-column {
            .first-level {
              width: 400px;
              > .sidebar-item {
                float: $lft;
                width: 50%;
                vertical-align: top;
              }
            }
          }
        }
        > .sidebar-item ul {
          position: absolute;
          left: 0px;
          top: auto;
          width: 220px;
          padding-bottom: 0px;
          z-index: 100;
          display: none;
          box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
        }
        > .mega-dropdown {
          position: static;
          .first-level {
            width: 100vw;
            > li {
              width: 25%;
              float: $lft;
            }
          }
        }
        > .sidebar-item:hover {
          background: rgba(0, 0, 0, 0.025);
        }
        > .sidebar-item:hover ul.first-level,
        > .sidebar-item:hover ul.first-level.collapse {
          display: block;
          &:after {
            position: absolute;
            content: "";
            background: rgba(0, 0, 0, 0.025);
            top: 0;
            height: 100%;
            width: 100%;
            left: 0;
            z-index: -1;
          }
        }
        > .sidebar-item ul.second-level {
          left: 220px;
          top: 0px;
        }
        > .sidebar-item:last-child > .first-level ul.second-level,
        > .sidebar-item .first-level .right-side-dd ul.second-level {
          right: 220px;
          top: 0px;
          left: auto;
        }
        ul.first-level > .sidebar-item:hover ul.second-level {
          display: block;
        }
        // >.sidebar-item > .has-arrow:after,
        .nav-small-cap,
        .sidebar-footer,
        .user-pro,
        .badge {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #main-wrapper[data-layout="horizontal"] {
    .sidebar-nav > ul > .sidebar-item > .sidebar-link i {
      display: none;
    }

    .sidebar-nav #sidebarnav > .sidebar-item {
      flex: auto;
    }
  }
}

@include media-breakpoint-down(md) {
  #main-wrapper[data-layout="horizontal"] {
    // 
    // Fixed Sidebar Layout
    // 
    &[data-sidebar-position="fixed"] {
      .left-sidebar {
        overflow: auto;
      }
    }
  }
}

// new main + horizontal combination style
@include media-breakpoint-up(lg) {
  #main-wrapper[data-layout="horizontal"] {
    .topbar .top-navbar .navbar-header {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  #main-wrapper[data-layout="horizontal"] {
    .topbar .top-navbar {
      display: block !important;
    }
  }
}
